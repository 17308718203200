<template>
  <layout-popover-menu
    v-bind="$attrs"
    v-on="$listeners"
    :is-activator-button="true"
  >
    <!-- Menu label -->
    <template v-slot:menuIconName>
      {{ iconInfo }}
    </template>

    <!-- Menu label -->
    <template v-slot:default>
      {{ menuLabelAbout }}
    </template>

    <!-- Popover About Dialog Icon -->
    <template v-slot:popoverIconName>
      {{ iconInfo }}
    </template>

    <!-- Popover Dialog Title -->
    <template v-slot:popoverTitle>
      {{ title }}
    </template>

    <!-- Popover Dialog Cancel -->
    <template v-slot:iconCrossName>
      <base-tooltip-button @click="closePopoverMenu()">
        <template v-slot:icon-name>
          {{ iconClose }}
        </template>
        <template v-slot:toolTip>
          {{ closePopoverTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- popover About Context-->
    <template v-slot:popoverContext>
      <about-public-access></about-public-access>
    </template>

    <!-- popover About Actions-->
    <template v-slot:popoverActions>
      <base-tooltip-button @click="closePopoverMenu">{{
        closePopoverLabel
      }}</base-tooltip-button>
    </template>
  </layout-popover-menu>
</template>

<script>
// design
import { iconClose, iconInfo } from "@/design/icon/iconConst";

// mixins
import { popoverMenuMixin } from "@/mixins/shared/base/popover/popoverMenuMixin";

export default {
  name: "PopoverMenuAboutPublicAccess",
  inheritAttrs: false,
  mixins: [popoverMenuMixin],
  components: {
    LayoutPopoverMenu: () =>
      import("@/components/shared/core/layout/menu/LayoutPopoverMenu"),
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton"),
    AboutPublicAccess: () =>
      import("@/components/shared/core/about/AboutPublicAccess")
  },
  data() {
    return {
      iconClose: iconClose,
      iconInfo: iconInfo
    };
  },
  computed: {
    menuLabelAbout() {
      return "Contact";
    },
    title() {
      return "Contact";
    },
    closePopoverTooltip() {
      return `${this.closePopoverLabel} ${this.menuLabelAbout}`;
    }
  }
};
</script>
