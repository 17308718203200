import { eventNames } from "@/model/common/events/eventConst";
import { commandNames } from "@/model/common/commands/commandModel";
import { alertableMixin } from "@/mixins/shared/alertable/alertableMixin";

/**
 * popoverMenuMixin encapsulate popover Menu functionality
 */
export const popoverMenuMixin = {
  mixins: [alertableMixin],
  computed: {
    /**
     * ok Dialog Label
     * @return {string} ok Dialog Label
     */
    okPopoverLabel() {
      return commandNames.ok;
    },

    /**
     * cancel Dialog Label
     * @return {string} cancel Dialog Label
     */
    cancelPopoverLabel() {
      return commandNames.cancel;
    },

    /**
     * close Dialog Label
     * @return {string} close Dialog Label
     */
    closePopoverLabel() {
      return commandNames.close;
    }
  },
  methods: {
    /**
     * Auto Close Menu
     * @return {Promise<void>}
     */
    async autoCloseMenu(message) {
      try {
        this.internalAlert = this.createAlert(
          this.alertTypeName.success,
          message
        );
        await this.delay();
        this.closePopoverMenu();
      } catch (e) {
        console.error(e);
      }
    },

    closePopoverMenu() {
      this.internalAlert = undefined;
      this.$emit(eventNames.close);
    },

    /**
     * wait before the specified function or code is executed.
     * @param {number} timeOut The time, in milliseconds that the timer should wait before the specified function or code is executed
     * @return {Promise<unknown>}
     */
    async delay(timeOut = 1500) {
      return new Promise(resolve => setTimeout(resolve, timeOut));
    }
  }
};
